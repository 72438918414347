import React, { useContext } from 'react';
import Header from "../components/Header";
import { AppState } from '../lib/context/AppProvider'

function SpaceCockpit() {
  const { user, isLocalDev } = useContext(AppState);
  const accessToken = user.access_token;
  
  const baseUrl = isLocalDev 
    ? 'https://spacecockpit.saberdevusa.xyz/'
    : `${window.location.origin.replace("sbms.","spacecockpit.")}/`;

  const iframeSrc = accessToken?.length > 0 ? `${baseUrl}?access_token=${accessToken}` : baseUrl;
  
  return (  
    <div data-testid="home-container" id="home-container">
      <Header
            showSideBarMenu={true}
            showDashboardMenu={false}
            height={56} 
            className="pb-5"
        />
      <iframe 
        title="SpaceCockpit"
        src= {iframeSrc}
        style={{
          width: '100%',
          height: 'calc(100vh - 56px - 12px)',
          border: 'none'
        }}
        allow='geolocation'
      ></iframe>
    </div>
  );
};

export default SpaceCockpit;
