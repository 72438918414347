import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Outlet, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Spacefana from "./pages/Spacefana";
import toastConfig from "./lib/config/toast";
import { AppProvider, AppState } from "./lib/context/AppProvider";
import DashboardProvider from "./lib/context/DashboardProvider";
import UnclassHeader from "./components/UnclassHeader";
import Account from "./pages/Account";
import Chat from "./pages/Chat";
import SpaceCockpit from "./pages/SpaceCockpit";
import './styles/App.css';
import Pair from "./pages/Pair";
import CommandControl from "./pages/CommandControl";
import Settings from "./pages/Settings";

export const ProtectedRoute = () => {
    const {user, pendingRedirect} = useContext(AppState);
    const location = useLocation();

    if (!user || !user.loggedIn || (!user.isGuest && !user.access_token)) {
        pendingRedirect.current = location?.pathname + location?.search;    
        return <Navigate to={"/"} replace />;
    }
    return <Outlet/>;
};

export const AppRoutes = ({dashboardElement}) => (
    <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<ProtectedRoute />}>
            {
                ["/dashboards/*"].map((p) =>
                    (<Route key={p} path={p} element={dashboardElement} />)
                )
            }
            <Route path="/spacecockpit" element={<SpaceCockpit />} />
            <Route path="/pair" element={<Pair />} />
            <Route path="/c2" element={<CommandControl />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/account" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
        </Route>
    </Routes>
);

export const AppContents = () => (
    <>
        <ToastContainer {...toastConfig} />
        <UnclassHeader />
        <AppRoutes dashboardElement={
            <DashboardProvider>
                <Spacefana />
            </DashboardProvider>
        } />
    </>
);

export const AppRouter = ({children}) => (
    <Router basename={"/"} future={{v7_startTransition: true,v7_relativeSplatPath: true}}>
        {children}
    </Router>
);

export const App = () => {
    return (
        <AppRouter>
            <AppProvider>
                <AppContents />
            </AppProvider>
        </AppRouter>
    );
};