export class Dot {
  constructor(halfx, halfy, options = {}) {
    this.halfx = halfx;
    this.halfy = halfy;
    
    const cryptoRandom = () => crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295;
    
    this.x = options.x ?? cryptoRandom() * halfx * 2;
    this.y = options.y ?? cryptoRandom() * halfy * 1.6;
    this.dx = options.dx ?? (cryptoRandom() - 0.5) * 2;
    this.dy = options.dy ?? (cryptoRandom() - 0.5) * 0.5;
    this.speed = options.speed ?? 1;
    this.size = options.size ?? cryptoRandom() * 5 + 1;
    this.color = options.color ?? Math.floor(cryptoRandom() * 256);
  }

  getPosition() {
    return { x: this.x, y: this.y };
  }

  move() {
    this.x += this.dx * this.speed;
    this.y += this.dy * this.speed;
    
    if (this.x < 0) this.x = this.halfx * 2;
    if (this.x > this.halfx * 2) this.x = 0;
    if (this.y < 0) this.y = this.halfy * 2;
    if (this.y > this.halfy * 2) this.y = 0;
    
    return this;
  }

  getRenderProperties() {
    const position = this.getPosition();
    return {
      ...position,
      size: this.size,
      color: this.color
    };
  }
}

export class Background {
  constructor(width, height, dotCount = 5000) {
    this.width = width;
    this.height = height;
    this.halfx = width / 2;
    this.halfy = height / 2;
    this.dotCount = dotCount;
    
    this.stars = this.createStars(dotCount - 20);
    this.satellites = this.createSatellites(20);
  }

  // Utility function for crypto random numbers between 0 and 1
  cryptoRandom() {
    return crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295;
  }

  createStars(count) {
    return Array.from({ length: count }, () => {
      return new Dot(this.halfx, this.halfy, {
        y: this.cryptoRandom() * this.height,
        dx: 0.1,
        dy: 0,
        speed: 0.1,
        size: this.cryptoRandom() * 2 + 0.5,
        color: 255
      });
    });
  }

  createSatellites(count) {
    return Array.from({ length: count }, () => {
      const angle = this.cryptoRandom() * Math.PI;
      return new Dot(this.halfx, this.halfy, {
        dx: Math.cos(angle),
        dy: Math.sin(angle) * 0.5,
        speed: 2 + this.cryptoRandom(),
        size: 3 + this.cryptoRandom() * 2,
        color: 200 + this.cryptoRandom() * 55
      });
    });
  }

  update() {
    const allDots = [
      ...this.stars.map(dot => dot.move()),
      ...this.satellites.map(dot => dot.move())
    ];
    return allDots.map(dot => dot.getRenderProperties());
  }
}

export class Renderer {
  constructor(canvas) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d', { alpha: false });
    this.lastFrame = 0;
    this.fps = 60;
    this.frameInterval = 1000 / this.fps;
  }

  render(dots, timestamp) {
    if (timestamp - this.lastFrame < this.frameInterval) return;
    this.lastFrame = timestamp;

    // Draw background
    this.context.fillStyle = '#000000';
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    
    // Draw dots
    this.context.beginPath();
    dots.forEach(({ x, y, size, color }) => {
      this.context.fillStyle = `rgb(${color},${color},${color},0.25)`;
      this.context.moveTo(x + size/2, y);
      this.context.arc(x, y, size/2, 0, Math.PI * 2);
    });
    this.context.fill();
  }
}

export function render(canvas) {
  const { background, renderer } = initialize(canvas);
  let isRunning = true;

  function animate(timestamp) {
    if (!isRunning) return;
    const dots = background.update();
    renderer.render(dots, timestamp);
    requestAnimationFrame(animate);
  }

  requestAnimationFrame(animate);
  
  return {
    stop: () => {
      isRunning = false;
    },
    background, 
    renderer
  };
}

export function initialize(canvas) {
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = "starfield";
    let unclassHeight = getComputedStyle(document.documentElement)
      .getPropertyValue("--unclass-header-height")
      .replace("px", "");

    if(isNaN(unclassHeight)) {
      unclassHeight = 0;
    }

    canvas.width = document.body.clientWidth;
    canvas.height = document.body.clientHeight - unclassHeight;

    document.body.appendChild(canvas);
  }

  const background = new Background(canvas.width, canvas.height);
  const renderer = new Renderer(canvas);

  return { background, renderer };
}