import React from 'react';
import logo from '../lib/assets/img/logo.png';
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content" data-testid="footer-content">
        <img src={logo} alt="Company Logo" className="footer-logo" />
      </div>
    </footer>
  );
};

export default Footer; 