// src/components/DashboardMenu.js
import React, { useContext } from "react";
import { ButtonGroup, Button, Dropdown, DropdownButton } from "react-bootstrap";
import PropTypes from "prop-types";
import { DashboardState, getDashboardIndex } from "../lib/context/DashboardProvider";

const DashboardMenu = ({ maxButtons }) => {
    const { dashIndex, goToDashboard, dashboards } = useContext(DashboardState);

    const openLink = (e) => {
        const dash = dashboards[getDashboardIndex(dashboards, e.target.textContent)];
        if (!dash || !dash.link) return;

        const url = new URL(dash.link);
        window.open(url.toString(), '_blank', 'noopener,noreferrer')?.focus();
    };

    const setDbId = (e) => {
        const newId = getDashboardIndex(dashboards, e.target.textContent);
        goToDashboard(newId);
    };

    const displayedDashboards = dashboards.slice(0, maxButtons);
    const dropdownDashboards = dashboards.slice(maxButtons);

    return (
        <ButtonGroup>
            {displayedDashboards.map((dashboard) => (
                <Button
                    onClick={(e) => setDbId(e)}
                    onContextMenu={(e) => openLink(e)}
                    key={`dashboard-${dashboard.name.toLowerCase().replace(/\s+/g, '-')}`}
                    variant={dashboards.findIndex(d => d.name === dashboard.name) === dashIndex ? "light" : "dark"}
                >
                    <span style={{ fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden" }}>
                        {dashboard.name}
                    </span>
                </Button>
            ))}
            {dropdownDashboards.length > 0 && (
                <DropdownButton
                    as={ButtonGroup}
                    title="More"
                    id="bg-nested-dropdown"
                    variant={dashIndex >= maxButtons ? "light" : "dark"}
                >
                    {dropdownDashboards.map((dashboard) => (
                        <Dropdown.Item
                            eventKey={dashboard.name}
                            onClick={(e) => setDbId(e)}
                            onContextMenu={(e) => openLink(e)}
                            key={`dropdown-${dashboard.name.toLowerCase().replace(/\s+/g, '-')}`}
                        >
                            {dashboard.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            )}
        </ButtonGroup>
    );
};

DashboardMenu.propTypes = {
    maxButtons: PropTypes.number.isRequired,
};

export default DashboardMenu;