import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form, Table, Accordion } from "react-bootstrap";
import { AppState } from "../lib/context/AppProvider";
import Header from "../components/Header";
import "../styles/page.css";

const ConstellationModal = ({ 
    show, 
    onHide, 
    type, 
    satellites,
    onSave,
    editMode = false,
    existingConstellation = { name: "", satellites: [] }
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [constellationName, setConstellationName] = useState(existingConstellation.name);
    const [selectedSatellites, setSelectedSatellites] = useState(existingConstellation.satellites);
    
    // Debounce search term updates
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Reset state when modal opens/closes or when editMode changes
    useEffect(() => {
        if (show) {
            if (editMode) {
                setConstellationName(existingConstellation.name);
                setSelectedSatellites(existingConstellation.satellites);
            } else {
                setConstellationName("");
                setSelectedSatellites([]);
            }
            setSearchTerm("");
            setDebouncedSearchTerm("");
        }
    }, [show, editMode, existingConstellation]);

    // Memoize the filtered satellites
    const filteredSatellites = useMemo(() => {
        if (!debouncedSearchTerm || debouncedSearchTerm.length < 2) {
            return selectedSatellites.map(id => {
                const sat = satellites.find(s => s.id === id);
                return sat || { id, name: id };
            });
        }

        // Check if the search term is a CSV list of NoradIDs
        if (debouncedSearchTerm.includes(',')) {
            const noradIds = debouncedSearchTerm.split(',').map(id => id.trim());
            return satellites.filter(sat => noradIds.includes(sat.id));
        }

        // Regular search by name or ID
        const searchTermLower = debouncedSearchTerm.toLowerCase();
        return satellites.filter(sat => 
            sat.id.toLowerCase().includes(searchTermLower) || 
            sat.name.toLowerCase().includes(searchTermLower)
        );
    }, [debouncedSearchTerm, selectedSatellites, satellites]);

    // Debug logging
    useEffect(() => {
        console.log('Filtered Satellites:', filteredSatellites);
    }, [filteredSatellites]);

    const getModalTitle = () => {
        const actionText = editMode ? "Edit" : "Create new";
        const teamText = type === "blue" ? "Blue" : "Red";
        return `${actionText} ${teamText} Constellation`;
    };

    const getSearchLabelText = () => {
        if (filteredSatellites.length > 0) {
            return `(${filteredSatellites.length} matches)`;
        }
        if (selectedSatellites.length > 0) {
            return `(${selectedSatellites.length} selected)`;
        }
        if (!debouncedSearchTerm || debouncedSearchTerm.length < 2) {
            return "(enter at least 2 characters to search)";
        }
        return "(0 matches)";
    };

    const handleSelectAll = () => {
        const filteredIds = filteredSatellites.map(sat => sat.id);
        const allSelected = filteredIds.every(id => selectedSatellites.includes(id));
        
        if (allSelected) {
            // Remove all filtered satellites from selection
            setSelectedSatellites(prev => 
                prev.filter(id => !filteredIds.includes(id))
            );
        } else {
            // Add all filtered satellites to selection
            setSelectedSatellites(prev => 
                [...new Set([...prev, ...filteredIds])]
            );
        }
    };

    const handleCheckboxChange = (satId) => {
        setSelectedSatellites(prev => 
            prev.includes(satId)
                ? prev.filter(id => id !== satId)
                : [...prev, satId]
        );
    };

    const renderTableBody = () => {
        if (filteredSatellites.length === 0) {
            return (
                <tr>
                    <td role="cell" colSpan="3" className="text-center">
                        No matches found
                    </td>
                </tr>
            );
        }

        return filteredSatellites.map((sat) => (
            <tr key={sat.id}>
                <td role="cell" className="text-center">
                    <Form.Check
                        type="checkbox"
                        checked={selectedSatellites.includes(sat.id)}
                        onChange={() => handleCheckboxChange(sat.id)}
                        aria-label={`Select ${sat.name}`}
                        id={`select-satellite-${sat.id}`}
                    />
                </td>
                <td role="cell">{sat.id}</td>
                <td role="cell">{sat.name}</td>
            </tr>
        ));
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton className="bg-dark text-white">
                <Modal.Title>
                    {getModalTitle()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-white">
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="constellation-name">Constellation Name</Form.Label>
                        <Form.Control
                            id="constellation-name"
                            type="text"
                            value={constellationName}
                            onChange={(e) => setConstellationName(e.target.value)}
                            className="bg-dark text-white"
                            aria-label="Constellation Name"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="satellite-search">
                            Search Satellites 
                            <small className="text-white-50 ms-2">
                                {getSearchLabelText()}
                            </small>
                        </Form.Label>
                        <Form.Control
                            id="satellite-search"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search by name or ID..."
                            className="bg-dark text-white"
                            aria-label="Search satellites"
                        />
                    </Form.Group>
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                        <Table striped bordered hover variant="dark">
                            <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#212529" }}>
                                <tr>
                                    <th style={{ width: "50px" }}>
                                        {filteredSatellites.length > 0 && (
                                            <Form.Check
                                                type="checkbox"
                                                checked={filteredSatellites.length > 0 && 
                                                    filteredSatellites.every(sat => {
                                                        return selectedSatellites.includes(sat.id);
                                                    })}
                                                onChange={handleSelectAll}
                                                className="text-center"
                                                aria-label="Select all satellites"
                                                id="select-all-checkbox"
                                            />
                                        )}
                                    </th>
                                    <th scope="col">SatNo</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableBody()}
                            </tbody>
                        </Table>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-white">
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button 
                    variant={type === "blue" ? "primary" : "danger"}
                    onClick={() => onSave({ name: constellationName, satellites: selectedSatellites })}
                    disabled={!constellationName || selectedSatellites.length === 0}
                >
                    {editMode ? "Update" : "Save"} Constellation
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConstellationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["blue", "red"]).isRequired,
    satellites: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    onSave: PropTypes.func.isRequired,
    editMode: PropTypes.bool,
    existingConstellation: PropTypes.shape({
        name: PropTypes.string,
        satellites: PropTypes.arrayOf(PropTypes.string)
    })
};

function Settings() {
    const { user, getAPIData, postData } = useContext(AppState);
    const [showModal, setShowModal] = useState(false);
    const [constellationType, setConstellationType] = useState("blue");
    const [satellites, setSatellites] = useState([]);
    const [blueConstellations, setBlueConstellations] = useState({});
    const [redConstellations, setRedConstellations] = useState({});
    const [editingConstellation, setEditingConstellation] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [satData, userData] = await Promise.all([
                    getAPIData("satellites"),
                    getAPIData("user-details")
                ]);

                if (!satData || !Array.isArray(satData)) {
                    console.error("Invalid satellite data received:", satData);
                    return;
                }

                const formattedSats = satData.map(sat => {
                    const [id, name] = sat.split(" - ");
                    return { id: id.trim(), name: name.trim() };
                });

                setSatellites(formattedSats);
                
                // Set constellations from user details
                if (userData?.Constellations) {
                    setBlueConstellations(userData.Constellations.blues || {});
                    setRedConstellations(userData.Constellations.reds || {});
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if(!user.isGuest) {
            fetchData();
        }
    }, [getAPIData, user]);

    const updateConstellations = async (newBlues, newReds) => {
        try {
            const payload = {
                blues: newBlues,
                reds: newReds
            };

            await postData("user-constellations", payload, "PATCH");
        } catch (error) {
            console.error("Failed to update constellations:", error);
            throw error;
        }
    };

    const updateConstellationState = (constellation, type, existingName = null) => {
        const constellations = type === "blue" ? blueConstellations : redConstellations;
        let updatedConstellations;

        if (existingName) {
            // Remove old constellation if name changed
            updatedConstellations = Object.fromEntries(
                Object.entries(constellations)
                    .filter(([key]) => key !== existingName)
            );
            updatedConstellations[constellation.name] = constellation.satellites;
        } else {
            updatedConstellations = {
                ...constellations,
                [constellation.name]: constellation.satellites
            };
        }

        if (type === "blue") {
            setBlueConstellations(updatedConstellations);
            return { blues: updatedConstellations, reds: redConstellations };
        } else {
            setRedConstellations(updatedConstellations);
            return { blues: blueConstellations, reds: updatedConstellations };
        }
    };

    const handleSaveConstellation = async (constellation) => {
        try {
            const existingName = editingConstellation?.name;
            const { blues: newBlues, reds: newReds } = updateConstellationState(
                constellation, 
                constellationType, 
                existingName
            );

            await updateConstellations(newBlues, newReds);
            setShowModal(false);
            setEditingConstellation(null);
        } catch (error) {
            console.error("Failed to save constellation:", error);
            // Revert state changes if update failed
            if (constellationType === "blue") {
                setBlueConstellations(() => blueConstellations);
            } else {
                setRedConstellations(() => redConstellations);
            }
        }
    };

    const handleDeleteConfirmation = async () => {
        if (!deleteConfirmation) return;

        const { name, type } = deleteConfirmation;
        try {
            let newBlues = blueConstellations;
            let newReds = redConstellations;

            if (type === "blue") {
                newBlues = Object.fromEntries(
                    Object.entries(blueConstellations)
                        .filter(([key]) => key !== name)
                );
                setBlueConstellations(newBlues);
            } else {
                newReds = Object.fromEntries(
                    Object.entries(redConstellations)
                        .filter(([key]) => key !== name)
                );
                setRedConstellations(newReds);
            }

            await updateConstellations(newBlues, newReds);
            setDeleteConfirmation(null);
        } catch (error) {
            console.error("Failed to delete constellation:", error);
            // Revert state changes if update failed
            if (type === "blue") {
                setBlueConstellations(() => blueConstellations);
            } else {
                setRedConstellations(() => redConstellations);
            }
        }
    };

    const handleDeleteConstellation = (name, type) => {
        setDeleteConfirmation({ name, type });
    };

    const handleShowModal = (type) => {
        setConstellationType(type);
        setEditingConstellation(null);  // Ensure we're not in edit mode
        setShowModal(true);
    };

    const handleEditConstellation = (name, type) => {
        const constellation = type === "blue" 
            ? blueConstellations[name] 
            : redConstellations[name];
            
        setEditingConstellation({
            name,
            satellites: constellation
        });
        setConstellationType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingConstellation(null);
    };

    const renderConstellations = (constellations, type) => (
        <div className="mt-3">
            {Object.entries(constellations).map(([name, sats]) => (
                <div 
                    key={name}
                    className="p-3 mb-2 rounded"
                    style={{ backgroundColor: "#1a1d20" }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                            <h6 className="mb-1 text-white">{name}</h6>
                            <small className="text-light">
                                {sats.length} satellites
                            </small>
                        </div>
                        <div>
                            <Button
                                variant={type === "blue" ? "outline-primary" : "outline-danger"}
                                size="sm"
                                className="me-2"
                                onClick={() => handleEditConstellation(name, type)}
                            >
                                Edit
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => handleDeleteConstellation(name, type)}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                    <div 
                        className="satellite-list" 
                        style={{ 
                            maxHeight: "100px", 
                            overflowY: "auto",
                            backgroundColor: "#212529",
                            borderRadius: "4px",
                            padding: "8px"
                        }}
                    >
                        {sats.map(satId => {
                            const satellite = satellites.find(s => s.id === satId);
                            return (
                                <div key={satId} className="small text-white ps-2">
                                    {satellite ? `${satId} - ${satellite.name}` : satId}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
            {Object.keys(constellations).length === 0 && (
                <div className="text-light text-center p-3">
                    No {type} constellations defined
                </div>
            )}
        </div>
    );

    return (  
        <>
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <div id="page-background">
                <section className="settings-section">
                    <Accordion alwaysOpen className="mb-3 settings-accordion">
                        <Accordion.Item eventKey="constellations" className="bg-dark border-0">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">CONSTELLATIONS</h4>
                            </Accordion.Header>
                            <Accordion.Body className="p-0 pt-4">
                                <div className="settings-subsection">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="settings-subsection-title">
                                            <span className="text-primary">Blue</span> Team
                                        </h6>
                                        <Button 
                                            variant="outline-primary"
                                            onClick={() => handleShowModal("blue")}
                                            className="mb-3"
                                            disabled={user.isGuest}
                                        >
                                            Add Blue Constellation
                                        </Button>
                                    </div>
                                    <p>Blue satellites will be monitored for threats against them.</p>
                                    <div className="mt-3" data-testid="blue-constellations">
                                        {renderConstellations(blueConstellations, "blue")}
                                    </div>
                                </div>
                                <hr style={{ borderColor: "#495057" }}/>
                                <div className="settings-subsection mt-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="settings-subsection-title">
                                            <span className="text-danger">Red</span> Team
                                        </h6>
                                        <Button 
                                            variant="outline-danger"
                                            onClick={() => handleShowModal("red")}
                                            className="mb-3"
                                            disabled={user.isGuest}
                                        >
                                            Add Red Constellation
                                        </Button>
                                    </div>
                                    <p>Red satellites will be monitored for maneuvers, photometry, and other pattern of life changes.</p>
                                    <div className="mt-3" data-testid="red-constellations">
                                        {renderConstellations(redConstellations, "red")}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="windu" className="bg-dark border-0 mt-3">
                            <Accordion.Header className="settings-accordion-header">
                                <h4 className="settings-section-title m-0">WINDU</h4>
                            </Accordion.Header>
                            <Accordion.Body className="p-0 pt-4">
                                {/* Content will be added later */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </section>
            </div>
            
            {showModal && (
                <ConstellationModal
                    show={showModal}
                    onHide={handleCloseModal}
                    type={constellationType}
                    satellites={satellites}
                    onSave={handleSaveConstellation}
                    editMode={!!editingConstellation}
                    existingConstellation={editingConstellation || { name: "", satellites: [] }}
                />
            )}

            <Modal 
                show={!!deleteConfirmation} 
                onHide={() => setDeleteConfirmation(null)}
                size="sm"
            >
                <Modal.Header closeButton className="bg-dark text-white">
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-white">
                    Are you sure you want to delete the constellation 
                    <strong> {deleteConfirmation?.name}</strong>?
                </Modal.Body>
                <Modal.Footer className="bg-dark text-white">
                    <Button 
                        variant="secondary" 
                        onClick={() => setDeleteConfirmation(null)}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="danger"
                        onClick={handleDeleteConfirmation}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Settings;
