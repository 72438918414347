import { createContext, useState, useMemo, useRef } from 'react';

export const AppState = createContext({});

const getBaseUrl = (isCAC = false) => {
    // Get the current domain and determine the base URL
    const currentDomain = window.location.hostname;
    if(currentDomain === 'localhost') return 'http://localhost:9001';
    else if (false === currentDomain.includes("sbms.")) // high side deployments use paths not subdomains
    return `https://${currentDomain}/nps`;
    else if(isCAC && currentDomain.includes("saberastro.com"))
    return `https://${currentDomain.replace('sbms.', 'nps.')}:444`; // CAC logins on saberastro.com only
    else
    return `https://${currentDomain.replace('sbms.', 'nps.')}`;
};

const getAPIData = async (path) =>{
    const response = await fetch(`${getBaseUrl()}/${path}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            // Access token is automatically added from httpOnly cookie
        },
    });
    return await response.json();
}

const postLogin = async (username, password) => {
    if(!username || !password) return;
    return await fetch(`${getBaseUrl()}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            "username": username,
            "password": password,
          },
    });
}

const postData = async (endpoint, data, method = "POST") => {
    return await fetch(`${getBaseUrl()}/${endpoint}`, {
        method: method,
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
};

export const AppProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        return {
            loggedIn: false,
            uuid: null,
            access_token: null,
            providers: [],
            isGuest: false,
        };
    });
    
    const pendingRedirect = useRef(null);
    
    const isLocalDev = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    
    const store = useMemo(() => ({
        user,
        setUser,
        pendingRedirect,
        isLocalDev,
        getBaseUrl,
        getAPIData,
        postLogin,
        postData,
    }), [user,isLocalDev, pendingRedirect]);

    return (
        <AppState.Provider value={store}>      
            {children}
        </AppState.Provider>
    );
};