const toastConfig = {
    position: "bottom-center",
    reverseOrder: true,
    gutter: 12,
    theme: "dark",
    hideProgressBar: true,
    containerStyle: {
        inset: '8rem',
        zIndex: 50000,
    },
    style: {
        borderRadius: '10px',
        color: '#fff',
        textAlign: 'center',
        minWidth: "250px",
    },
    toastOptions: {
        success: {
            iconTheme: {
                primary: '#444444',
                secondary: '#ffc45d'
            }
        },
        loading: {
            iconTheme: {
                primary: '#444444',
                secondary: '#ffc45d'
            }
        },
        error: {
            iconTheme: {
                primary: '#444444',
                secondary: '#ffc45d'
            }
        }
    }
};

export default toastConfig;
