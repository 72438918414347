import React from "react";
import Row from "react-bootstrap/Row";

const isLowside = () => {
    return ["localhost", "saberdevusa.xyz", "saberastro.com", "spacecockpit.space"]
        .some(domain => window.location?.hostname?.includes(domain));
}

const UnclassHeader = () => {
    const style = {
        height: "var(--unclass-header-height)",
        minHeight: "var(--unclass-header-height)",
        backgroundColor: "#1ab000",
        color: "#ffffff",
        fontSize: "12px",
        textAlign: "center",
        verticalAlign: "top",
        lineHeight: "var(--unclass-header-height)"
    };
    
    return (
        <div className="bg-dark">
            {isLowside() && (<Row className="topbar g-0 p-0" data-testid="unclass-header" style={style}><p>UNCLASSIFIED – (IMPACT LEVEL 2)</p>
            </Row>)}
        </div>
    );
};

export default UnclassHeader;
