import React from 'react';
import Header from "../components/Header";

function NotFound() {

    return (  
        <div data-testid="home-container" id="home-container">
            <Header
                showSideBarMenu={true}
                showDashboardMenu={false}
                height={56} 
                className="pb-5"
            />
            <main style={{color: "white", textAlign: "center"}}>
              <br />
              <h1>404</h1>
              <p>Page not found</p>

            </main>
        </div>
    );
};

export default NotFound;
