import React, {useState, useEffect, useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import logo from "../lib/assets/img/logo.png";
import mobileLogo from "../lib/assets/img/mobileLogo.png";
import spaceCockpitLogo from "../lib/assets/img/spacecockpit.png";
import PropTypes from "prop-types";
import {useMediaQuery} from "react-responsive";
import { FiMenu  } from "react-icons/fi";
import SideBar from "./SideBar";
import { DashboardState } from "../lib/context/DashboardProvider";
import DashboardMenu from "./DashboardMenu";
import { AppState } from "../lib/context/AppProvider";

export const MAX_HEADER_BUTTONS = [2, 4, 5, 12];

const Header = ({
    height,
    showDashboardMenu = true,
    showSideBarMenu = true,
}) => {
    const {user} = useContext(AppState);
    const [showMenu, setShowMenu] = useState(false);
    const [numAlerts, setNumAlerts] = useState(0);

    // Preload the logo images
    useEffect(() => {
        const preloadImages = [logo, spaceCockpitLogo];
        preloadImages.forEach(src => {
            const img = new Image();
            img.src = src;
        });
    }, []);

    const style = {
        height: height,
        minHeight: height,
    };
    const isVeryWideScreen = useMediaQuery({query: "(min-width: 1750px)"});
    const isWideScreen = useMediaQuery({query: "(min-width: 950px)"}) && !isVeryWideScreen;
    const isNarrowScreen = useMediaQuery({query: "(min-width: 700px)"}) && !isWideScreen && !isVeryWideScreen;
    const isMobile = useMediaQuery({query: "(max-width: 700px)"});

    let maxButtons;
    if(isVeryWideScreen) {
        maxButtons = MAX_HEADER_BUTTONS[3];
    }
    else if(isWideScreen) {
        maxButtons = MAX_HEADER_BUTTONS[2];
    } else {
        maxButtons = isNarrowScreen ? MAX_HEADER_BUTTONS[1] : MAX_HEADER_BUTTONS[0];
    }

    const handleShow = () => {
        setShowMenu(!showMenu);
        setNumAlerts(0);
    };
    const handleClose = () => {
        setShowMenu(false);
    };
    const handleRefresh = () => {
        window.location.href = "/";
    };

    return (
        <div className="bg-dark" data-testid="mock-header">
            <Row className="topbar g-0 p-0" style={style}>
                <Col xs={1} className="pt-2">
                    <img
                        className="logo"
                        src={(isMobile || isNarrowScreen) ? mobileLogo : logo}
                        onClick={handleRefresh}
                        alt="logo"
                        height={38}
                        style={{cursor: "pointer", marginLeft:"10px"}}
                    />
                </Col>
                
                    <Col 
                        className="text-center"
                        style={{
                            marginTop:"10px",
                        }}
                    >
                        {showDashboardMenu && DashboardState && (
                            <DashboardMenu maxButtons={maxButtons} />
                        )}
                    </Col>

                {showSideBarMenu && (
                    <>
                    <Col xs={1} style={{
                        marginTop:"10px"
                    }} >
                        <div 
                            className="float-end"
                            style={{marginRight:"10px",
                                        display: "flex", alignItems: "center"}}>
                                {!((isMobile || isNarrowScreen) && showDashboardMenu && DashboardState) && (
                                <div className="text-white" 
                                    style={{
                                        float: "left",
                                        marginRight: "10px",
                                        height: "100%",
                                    }}
                                >{user.username}</div>
                                )}
                                <Button
                                    variant="dark"
                                    onClick={handleShow}
                                >
                                    {numAlerts ? (<div style={{position: "absolute",
                                        color:"rgb(255, 193, 7)",
                                        backgroundColor:"rgb(33, 37, 41)",
                                        fontSize: "12px",
                                        marginTop:"20px",
                                        marginLeft:"-20px",
                                        borderRadius:"100%",
                                        width:"20px",
                                        height:"20px"
                                    }}>{numAlerts}</div>) : null}
                                    <FiMenu/>
                                </Button>
                        </div>
                    </Col>
                    <SideBar
                        visible={showMenu}
                        handleClose={handleClose}
                        setNumAlerts={setNumAlerts}
                        spaceCockpitLogo={spaceCockpitLogo}
                    />
                    </>
                )}
            </Row>
        </div>
    );
};

Header.propTypes = {
    height: PropTypes.node.isRequired,
    showDashboardMenu: PropTypes.bool,
    showSideBarMenu: PropTypes.bool,
    className: PropTypes.string,
};

export default Header;
