import React, { useContext, useEffect } from 'react';
import Header from "../components/Header";
import { DashboardState } from '../lib/context/DashboardProvider';

function Spacefana() {

  const {
    currentDashboardLink,
  } = useContext(DashboardState);
  
  useEffect(() => {
    // Clean up any leftover canvas as a safety measure
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.remove();
    }
  }, []);

  return (  
    <div data-testid="home-container" id="home-container">
      <Header height={56} className="pb-5"/>
      <iframe 
        title="Spacefana"
        src={currentDashboardLink}
        style={{
          width: '100%',
          height: 'calc(100vh - 56px - 12px)',
          border: 'none'
        }}
        allow='geolocation'
      ></iframe>
    </div>
  );
};

export default Spacefana;
